import React, { useState } from "react";
import { FormHelperText, Grid } from "@mui/material";
import styles from "./children.module.css";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Formik } from "formik";
import { ChildrenValidationSchema } from "../../schema/clientForm";
import { useDispatch, useSelector } from "react-redux";
import { setStepData } from "../../redux/slice";
import { clientFormStyles } from "../client-form/styles";
import { Link } from "react-router-dom";

const ClientChildren = ({
  handleNextButtonClick,
  handleBackButtonClick,
  step,
  handleSignUp,
}) => {
  const step1Data = useSelector((state) => state?.slice?.signup?.step1Data);
  const step2Data = useSelector((state) => state?.slice?.signup?.step2Data);
  const step3Data = useSelector((state) => state?.slice?.signup?.step3Data);
  const [userData2, setUserData2] = React.useState({ ...step3Data });
  const dispatch = useDispatch();
  const steps = [0, 1, 2];


  React.useEffect(() => {
    setUserData2({ ...step3Data })
  }, [step3Data])



  return (
    <Grid md={12} xs={12}>
      <Grid className="client-checkbox">
        <Formik
          initialValues={userData2}
          onSubmit={(values) => {
            dispatch(setStepData({ step: 3, data: values }));
            handleSignUp(values);
            //</Grid>mutate(data);
          }}
          validationSchema={ChildrenValidationSchema}
        >
          {(props) => (
            <form onSubmit={props.handleSubmit}>
              <div>
                <div className={styles.nameFeildDiv}>
                  <div>
                    <div>
                      <p className={styles.OptionFeildLabel}>No. of Children</p>
                    </div>
                    <div className={styles.checboxDiv}>
                      <div>
                        <FormControlLabel
                          sx={clientFormStyles.radioBtnLabel}
                          id="children"
                          control={
                            <Checkbox
                              checked={props.values.children === "0"}
                              onChange={() =>
                                props.setFieldValue("children", "0")
                              }
                            />
                          }
                          label="None"
                        />
                      </div>
                      <div>
                        <FormControlLabel
                          sx={clientFormStyles.radioBtnLabel}
                          id="children"
                          control={
                            <Checkbox
                              checked={props.values.children === "1"}
                              onChange={() =>
                                props.setFieldValue("children", "1")
                              }
                            />
                          }
                          label="1"
                        />
                      </div>
                      <div>
                        <FormControlLabel
                          sx={clientFormStyles.radioBtnLabel}
                          id="children"
                          control={
                            <Checkbox
                              checked={props.values.children === "2"}
                              onChange={() =>
                                props.setFieldValue("children", "2")
                              }
                            />
                          }
                          label="2"
                        />
                      </div>
                      <div>
                        <FormControlLabel
                          sx={clientFormStyles.radioBtnLabel}
                          id="children"
                          control={
                            <Checkbox
                              checked={props.values.children === "3"}
                              onChange={() =>
                                props.setFieldValue("children", "3")
                              }
                            />
                          }
                          label="3"
                        />
                      </div>
                      <div>
                        <FormControlLabel
                          sx={clientFormStyles.radioBtnLabel}
                          id="children"
                          control={
                            <Checkbox
                              checked={props.values.children === "more"}
                              onChange={() =>
                                props.setFieldValue("children", "more")
                              }
                            />
                          }
                          label="More"
                        />
                      </div>
                    </div>

                    {props.errors.children && props.touched.children && (
                      <FormHelperText error sx={{ marginLeft: "70px" }}>
                        {props.errors.children}
                      </FormHelperText>
                    )}

                    {/* consent form */}
                    <div>
                      <div>
                        <p className={styles.OptionFeildLabel}>I hereby:</p>
                      </div>
                      <div className={styles.checboxDiv}>
                        <FormControlLabel
                          sx={clientFormStyles.radioBtnLabel}
                          id="consent_form"
                          control={
                            <Checkbox
                              checked={props.values.consent_form}
                              onChange={(value) => {
                                if (props.values.consent_form) {
                                  props.setFieldValue("consent_form", false);
                                } else {
                                  props.setFieldValue("consent_form", true);
                                }
                              }}
                            />
                          }
                          label={
                            <span>
                              I agree to
                              <Link
                                to="/consent-form"
                                style={{
                                  textDecoration: "underline",
                                  marginLeft: "5px",
                                  color: "#3e5378",
                                  // fontWeight: "600px",
                                }}
                                target="_blank"
                              >
                                consent form.
                              </Link>
                            </span>
                          }
                        />

                        {props.errors.consent_form &&
                          props.touched.consent_form && (
                            <FormHelperText error sx={{ marginLeft: "35px" }}>
                              {props.errors.consent_form}
                            </FormHelperText>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
                <div></div>
                <div className={styles.stepperBtn}>
                  <Button
                    className={styles?.naviBtn}
                    color="primary"
                    disabled={step === 0}
                    onClick={handleBackButtonClick}
                    sx={{ mr: 1 }}
                  >
                    Back
                  </Button>

                  <Button className={styles?.naviBtn} type="submit">
                    {step === steps.length - 1 ? "Done" : "Next"}
                  </Button>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};

export default ClientChildren;
