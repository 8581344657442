import { Routes, Route, Navigate } from "react-router-dom";
import PrivateRoutes from "../private-routes";
import {
  getAccessToken,
  getIsScAnswered,
  getUserType,
} from "../../utils/local-storage";
import { Suspense, lazy } from "react";
import FallbackLoader from "../../components/fallback-loader";
import AuthLayout from "../../layout/auth-layout";
import { lazyWithDelay } from "../../utils/helpers";
import LoginPage from "../../pages/login";
import SignupPage from "../../pages/signup";
const CorporateSignup = lazyWithDelay(() =>
  import("../../pages/corporate-signup")
);
const ForgotPasswordPage = lazyWithDelay(() =>
  import("../../pages/forgot-password")
);
const OtpPage = lazyWithDelay(() => import("../../pages/otp"));
const ReschedulePolicy = lazyWithDelay(() =>
  import("../../pages/reschedule-policy")
);
const PrivacyPolicy = lazyWithDelay(() => import("../../pages/privacy policy"));
const TermsAndConditions = lazyWithDelay(() =>
  import("../../pages/terms-and-condition")
);
const CorpClientSignupRedirection = lazyWithDelay(() =>
  import("../../pages/corp-client-signup-redirection")
);
const ResetPasswordPage = lazyWithDelay(() =>
  import("../../pages/reset-password")
);
const OtpSignup = lazyWithDelay(() => import("../../pages/otp-signup"));

const ConsentForm = lazy(() => import("../../pages/consent-form-page"));
const SessionList = lazy(() => import("../../pages/assignments/session-list"));
const TaskBySessionID = lazy(() =>
  import("../../pages/assignments/task-by-session-id")
);
const ScreeningQuestions = lazy(() =>
  import("../../pages/screening-questions")
);
const Profile = lazy(() => import("../../pages/profile/Profile"));
const ContactUsPage = lazy(() => import("../../pages/contact"));
const AppointmentsPage = lazy(() => import("../../pages/appointments"));
const AssignmentsPage = lazy(() => import("../../pages/assignments"));
const Password = lazy(() => import("../../pages/password-screen/Password"));
const Notification = lazy(() => import("../../pages/notification-screen"));
const PaymentDetails = lazy(() => import("../../pages/payment-screen/Payment"));
const Billing = lazy(() => import("../../pages/billing"));
const BookAppointmentLayout = lazy(() =>
  import("../../pages/book-appointment")
);
const RescheduleCancelAppointment = lazy(() =>
  import("../../pages/reschedule-cancel-appointment")
);
const ChangeProvider = lazy(() => import("../../pages/change-provider"));
const ProviderDetails = lazy(() => import("../../pages/provider-details"));
const Journaling = lazy(() => import("../../pages/journaling-page"));
const AppointmentList = lazy(() => import("../../pages/appointment-list"));
const Calendar = lazy(() => import("../../pages/calendar"));
const Task = lazy(() => import("../../pages/task"));
const SessionBookAppointment = lazy(() =>
  import("../../components/session-book-appointment")
);
const CorporateSessionBookAppointment = lazy(() =>
  import("../../components/corporate-session-book-appointment")
);
const Session = lazy(() =>
  import("../../components/provider-appointment-session")
);
const SessionPayment = lazy(() => import("../../components/session-payment"));
const MobileDashboard = lazy(() =>
  import("../../pages/customer-dashboard/mobile-dashboard")
);
const TabletDashboard = lazy(() =>
  import("../../pages/customer-dashboard/tablet-dashboard")
);
const Dashboard = lazy(() => import("../../pages/customer-dashboard"));
const MobileLayout = lazy(() => import("../../layout/mobile-dashboard-layout"));
const SettingsLayout = lazy(() => import("../../layout/settings-layout"));
const ProviderProfile = lazy(() =>
  import("../../pages/reschedule-cancel-appointment/mobile-ui/ProviderProfile")
);
const NotFound = lazy(() => import("../../pages/404-not-found"));

const AppRoutes = () => {
  const token = getAccessToken();
  const isScAnswered = JSON.parse(getIsScAnswered());
  const userType = getUserType();
  return (
    <Suspense fallback={<FallbackLoader />}>
      <Routes>
        <Route
          path="/"
          element={
            <Navigate
              to={
                token
                  ? userType === "client"
                    ? isScAnswered
                      ? "/dashboard"
                      : "/screening-questions"
                    : "/dashboard-provider"
                  : "/auth/login"
              }
            />
          }
        />

        <Route
          path="/auth/login"
          element={
            token ? (
              <Navigate to='/' />
            ) : (
              <AuthLayout>
                <LoginPage />
              </AuthLayout>
            )
          }
        />
        {/* Login route */}

        {/* signUp Route */}

        <Route
          path="/mobile-dashboard"
          element={
            <PrivateRoutes>
              <MobileDashboard />
            </PrivateRoutes>
          }
        />
        <Route
          path="/provider-profile"
          element={
            <PrivateRoutes>
              <ProviderProfile />
            </PrivateRoutes>
          }
        />
        <Route
          path="/privacy-policy"
          element={
            <Suspense fallback={<FallbackLoader />}>
              <PrivacyPolicy />
            </Suspense>
          }
        />

        <Route
          path="/reschedule-policy"
          element={
            <Suspense fallback={<FallbackLoader />}>
              <ReschedulePolicy />
            </Suspense>
          }
        />

        <Route
          path="/consent-form"
          element={
            <Suspense fallback={<FallbackLoader />}>
              <ConsentForm />
            </Suspense>
          }
        />

        <Route
          path="/terms-and-condition"
          element={
            <Suspense fallback={<FallbackLoader />}>
              <TermsAndConditions />
            </Suspense>
          }
        />

        <Route
          path="/corp-client-signup-redirection"
          element={
            <Suspense fallback={<FallbackLoader />}>
              <CorpClientSignupRedirection />
            </Suspense>
          }
        />

        <Route
          path="/corp-client-signup-redirection/:email"
          element={
            <Suspense fallback={<FallbackLoader />}>
              <CorpClientSignupRedirection />
            </Suspense>
          }
        />

        <Route
          path="/tablet-dashboard"
          element={
            <PrivateRoutes>
              <TabletDashboard />
            </PrivateRoutes>
          }
        />

        <Route
          path="/mobile-layout"
          element={
            <PrivateRoutes>
              <MobileLayout />
            </PrivateRoutes>
          }
        />

        <Route
          path="/auth/signup"
          element={
            <AuthLayout>
              <SignupPage />
            </AuthLayout>
          }
        />

        <Route
          path="/auth/corporate-signup"
          element={
            <AuthLayout>
              <CorporateSignup />
            </AuthLayout>
          }
        />

        <Route
          path="/assignments/journal/:id"
          element={
            <PrivateRoutes>
              <Journaling />
            </PrivateRoutes>
          }
        />

        <Route
          path="/assignments/task/:id"
          element={
            <PrivateRoutes>
              <Task />
            </PrivateRoutes>
          }
        />

        <Route
          path="/assignments/task-session/:id"
          element={
            <PrivateRoutes>
              <TaskBySessionID />
            </PrivateRoutes>
          }
        />

        <Route
          path="/assignments/journal"
          element={
            <PrivateRoutes>
              <Journaling />
            </PrivateRoutes>
          }
        />

        <Route
          path="/auth/otp/:email"
          element={
            <AuthLayout>
              <OtpPage />
            </AuthLayout>
          }
        />

        <Route
          path="/auth/otp"
          element={
            <PrivateRoutes withoutDashboardLayout>
              <OtpPage />
            </PrivateRoutes>
          }
        />

        <Route
          path="/auth/otp-signup/:email"
          element={
            <AuthLayout>
              <OtpSignup />
            </AuthLayout>
          }
        />

        {/* signUp Route */}

        {/* Forget Password route  */}

        <Route
          path="/auth/forgot-password"
          element={
            <AuthLayout>
              <ForgotPasswordPage />
            </AuthLayout>
          }
        />
        {/* Forget Password route  */}

        {/* Reset Password route */}

        <Route
          path="/auth/reset-password"
          element={
            <AuthLayout>
              <ResetPasswordPage />
            </AuthLayout>
          }
        />

        {/* Reset Password route */}

        {/* customer dashboard route */}
        <Route
          path="/dashboard"
          element={
            <PrivateRoutes>
              <Dashboard />
            </PrivateRoutes>
          }
        />

        <Route
          path="/settings"
          element={
            <PrivateRoutes>
              <SettingsLayout />
            </PrivateRoutes>
          }
        >
          <Route path="profile" element={<Profile />} />
          <Route path="password" element={<Password />} />
          <Route
            path="notifications"
            element={
              <PrivateRoutes>
                <Notification />
              </PrivateRoutes>
            }
          />

          <Route
            path="payment-details"
            element={
              <PrivateRoutes>
                <PaymentDetails />
              </PrivateRoutes>
            }
          />

          <Route
            path="billing-invoice"
            element={
              <PrivateRoutes>
                <Billing />
              </PrivateRoutes>
            }
          />
        </Route>

        <Route
          path="/book-appointment"
          // add select providers screen here:
          element={
            <PrivateRoutes>
              <BookAppointmentLayout />
            </PrivateRoutes>
          }
        ></Route>

        <Route
          path="/book-appointment/session-payment/:providerId/:slotId"
          element={
            <PrivateRoutes>
              <SessionPayment />
            </PrivateRoutes>
          }
        />

        <Route
          path="/book-appointment/time-slot/:providerId/:sessionId"
          element={
            <PrivateRoutes>
              <Session />
            </PrivateRoutes>
          }
        />

        <Route
          path="/book-appointment/time-slot/:providerId"
          element={
            <PrivateRoutes>
              <Session />
            </PrivateRoutes>
          }
        />

        <Route
          path="/book-appointment/session-payment/:providerId/:slotId/:sessionId"
          // add time slot component here:
          element={
            <PrivateRoutes>
              <SessionPayment />
            </PrivateRoutes>
          }
        />

        <Route
          path="/booked"
          // add time slot component here:
          element={
            <PrivateRoutes>
              <SessionBookAppointment />
            </PrivateRoutes>
          }
        />

<Route
          path="/corporate-booked"
          // add time slot component here:
          element={
            <PrivateRoutes>
              <CorporateSessionBookAppointment />
            </PrivateRoutes>
          }
        />

        <Route
          path="/reschedule-appointment"
          element={
            <PrivateRoutes>
              <RescheduleCancelAppointment />
            </PrivateRoutes>
          }
        />
        <Route
          path="/change-provider"
          element={
            <PrivateRoutes>
              <ChangeProvider />
            </PrivateRoutes>
          }
        />

        <Route
          path="/appointment-list"
          element={
            <PrivateRoutes>
              <AppointmentList />
            </PrivateRoutes>
          }
        />

        <Route
          path="/appointment-list/:id"
          element={
            <PrivateRoutes>
              <AppointmentList />
            </PrivateRoutes>
          }
        />

        <Route
          path="/contact-us"
          element={
            <PrivateRoutes>
              <ContactUsPage />
            </PrivateRoutes>
          }
        />

        <Route
          path="/appointments"
          element={
            <PrivateRoutes>
              <AppointmentsPage />
            </PrivateRoutes>
          }
        />

        <Route
          path="/assignments"
          element={
            <PrivateRoutes>
              <AssignmentsPage />
            </PrivateRoutes>
          }
        />

        <Route
          path="/assignments/task-session"
          element={
            <PrivateRoutes>
              <SessionList />
            </PrivateRoutes>
          }
        />

        <Route
          path="/calendar"
          element={
            <PrivateRoutes>
              <Calendar />
            </PrivateRoutes>
          }
        />

        <Route
          path="/screening-questions"
          element={
            <PrivateRoutes withoutDashboardLayout>
              <ScreeningQuestions />
            </PrivateRoutes>
          }
        />

        <Route
          path="/provider-details/:id"
          element={
            <PrivateRoutes>
              <ProviderDetails />
            </PrivateRoutes>
          }
        />

        <Route
          path="/contact-us"
          element={
            <PrivateRoutes>
              <ContactUsPage />
            </PrivateRoutes>
          }
        />

        <Route path="*" element={<NotFound />} />
        {/* <Route path="*" element={<Navigate to="/auth/login" />} /> */}
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
