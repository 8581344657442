import { Autocomplete, FormHelperText, Paper, TextField } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useQuery } from "react-query";
import styles from "./clientform.module.css";
import { networkRequest } from "../../utils/api";
import { clientFormStyles } from "./styles";
import Loader from "../loader";

const CountryDropDown = ({ formikProps, disabled }) => {
  const [selectedCountryId, setSelectedCountryId] = React.useState("");
  const [selectedStateId, setStateId] = React.useState("");
  const countryListRef = useRef([]);
  const statesListRef = useRef([]);
  const cityListRef = useRef([]);

  const { data: countryNames, isLoading: isFetchingCountries } = useQuery({
    queryKey: ["GET_COUNTRIES"],
    queryFn: () =>
      networkRequest({
        method: "get",
        url: "/countries",
      }),
    select: (response) => {
      countryListRef.current = [...response];
      return countryListRef.current?.map((country) => country.name);
    },
  });

  const { data: stateNames, isLoading: isFetchingState } = useQuery({
    queryKey: ["GET_STATES", selectedCountryId],
    queryFn: () =>
      networkRequest({
        method: "get",
        url: `/states/${selectedCountryId}`,
      }),
    enabled: !!selectedCountryId,
    select: (response) => {
      statesListRef.current = [...response];
      return statesListRef.current?.map((country) => country.name);
    },
    onSuccess: () => {
      setStateId("");
    },
  });

  const { data: cityNames, isLoading: isFetchingCities } = useQuery({
    queryKey: ["GET_CITIES", selectedStateId],
    queryFn: () =>
      networkRequest({
        method: "get",
        url: `/cities/${selectedStateId}`,
      }),
    enabled: !!selectedStateId,
    select: (response) => {
      cityListRef.current = [...response];
      return cityListRef.current?.map((state) => state.name);
    },
  });


  useEffect(() => {
    if (countryListRef.current.length > 0 && formikProps?.values?.country) {
      const selectedCountry = countryListRef.current.find(
        (country) => country.name === formikProps.values.country
      );

      if (selectedCountry) {
        let id = selectedCountry.id;
        setSelectedCountryId(id);
      }
    }
    if (statesListRef.current.length > 0 && formikProps?.values?.state) {
      const selectedState = statesListRef.current.find(
        (state) => state.name === formikProps.values.state
      );

      if (selectedState) {
        let id = selectedState.id;
        setStateId(id);
      }
    }
  }, [
    countryListRef.current,
    formikProps?.values?.country,
    formikProps?.values?.state,
    statesListRef.current,
  ]);
  return (
    <>
      {isFetchingCountries ? (
        <Loader />
      ) : (
        <div className={styles.nameFeildDiv}>
          <div>
            <div>
              <p className={styles.feildLabel}>Country</p>
            </div>
            {/* COUNTRY */}
            <Paper className={styles.otherInput}>
              <Autocomplete
                sx={clientFormStyles.dropDownInput}
                id="country"
                disabled={disabled}
                onChange={(_, newValue) => {
                  formikProps?.setFieldValue("country", newValue);
                  formikProps?.setFieldValue("state", "");
                  formikProps?.setFieldValue("city", "");

                  if (countryListRef?.current?.length !== 0) {
                    const selectedCountry = countryListRef.current?.find(
                      (country) => country?.name === newValue
                    );
                    let id = selectedCountry?.id;
                    setSelectedCountryId(id);
                  }
                }}
                disableClearable
                value={formikProps?.values?.country}
                options={countryNames}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Select Country" />
                )}
              />
            </Paper>
            <FormHelperText error sx={{ marginLeft: "10px" }}>
              {formikProps.touched.country && formikProps.errors.country}
            </FormHelperText>
          </div>
        </div>
      )}

      {/* STATE */}
      {isFetchingState ? (
        <Loader />
      ) : selectedCountryId || formikProps?.values?.country ? (
        <div className={styles.nameFeildDiv}>
          <div>
            <div>
              <p className={styles.feildLabel}>State</p>
            </div>
            <Paper className={styles.otherInput}>
              <Autocomplete
                sx={clientFormStyles.dropDownInput}
                id="state"
                disabled={disabled}
                value={formikProps?.values?.state}
                disableClearable
                onChange={(_, newValue) => {
                  formikProps?.setFieldValue("state", newValue);
                  formikProps?.setFieldValue("city", "");
                  // Clear the error and mark the field as not touched
                  formikProps.setFieldError("state", "");
                  formikProps.setFieldTouched("state", false);

                  formikProps.setFieldError("city", "");
                  formikProps.setFieldTouched("city", false);

                  const selectedState = statesListRef.current?.find(
                    (state) => state.name === newValue
                  );
                  let id = selectedState?.id;
                  setStateId(id);
                }}
                options={stateNames || []}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Select State" />
                )}
              />
            </Paper>
            <FormHelperText error sx={{ marginLeft: "10px" }}>
              {formikProps.touched.state && formikProps.errors.state}
            </FormHelperText>
          </div>
        </div>
      ) : (
        ""
      )}

      {/* CITY */}

      {isFetchingCities ? (
        <Loader />
      ) : selectedStateId || formikProps?.values?.city ? (
        <div className={styles.nameFeildDiv}>
          <div>
            <div>
              <p className={styles.feildLabel}>City</p>
            </div>
            <Paper className={styles.otherInput}>
              <Autocomplete
                sx={clientFormStyles.dropDownInput}
                id="city"
                disableClearable
                disabled={disabled}
                value={formikProps?.values?.city}
                onChange={(_, newValue) => {
                  formikProps?.setFieldValue("city", newValue);
                }}
                options={cityNames || []}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Select City" />
                )}
              />
            </Paper>
            <FormHelperText error sx={{ marginLeft: "10px" }}>
              {formikProps.touched.city && formikProps.errors.city}
            </FormHelperText>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default CountryDropDown;
