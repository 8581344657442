import React, { useState } from "react";
import { FormHelperText, Grid } from "@mui/material";
import styles from "./checkbox.module.css";
import formImg from "../../images/login/client-services.png";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Formik } from "formik";
import { RelationshipStatusValidationSchema } from "../../schema/clientForm";
import { useDispatch, useSelector } from "react-redux";
import { setStepData } from "../../redux/slice";
import { clientFormStyles } from "../client-form/styles";

const ClientCheckBox = ({
  handleNextButtonClick,
  handleBackButtonClick,
  step,
}) => {
  const step2Data = useSelector((state) => state?.slice?.signup?.step2Data);
  const [userData1, setUserData1] = useState({ ...step2Data });

  const steps = [0, 1, 2];
  const dispatch = useDispatch();


  React.useEffect(() => {
    setUserData1({ ...step2Data })
  }, [step2Data])


  return (
    <Grid md={12} xs={12}>
      <Grid className="client-checkbox">
        <Formik
          initialValues={userData1}
          onSubmit={(values) => {
            dispatch(setStepData({ step: 1, data: values }));
            handleNextButtonClick();
          }}
          validationSchema={RelationshipStatusValidationSchema}
        >
          {(props) => (
            <form onSubmit={props.handleSubmit}>
              <div className={styles.nameFeildDiv}>
                <div>
                  <div>
                    <p className={styles.OptionFeildLabel}>
                      Relationship Status
                    </p>
                  </div>
                  <div className={styles.checboxDiv}>
                    <div>
                      <FormControlLabel
                        sx={clientFormStyles.radioBtnLabel}
                        id="checbox"
                        control={
                          <Checkbox
                            checked={
                              props.values.relationship_status === "single"
                            }
                            onChange={() =>
                              props.setFieldValue(
                                "relationship_status",
                                "single"
                              )
                            }
                          />
                        }
                        label="Single"
                      />
                    </div>

                    <div>
                      <FormControlLabel
                      sx={clientFormStyles.radioBtnLabel}
                      id="checbox"
                        className={styles.checkLabel}
                        control={
                          <Checkbox
                            checked={
                              props.values.relationship_status ===
                              "in a relationship"
                            }
                            onChange={() =>
                              props.setFieldValue(
                                "relationship_status",
                                "in a relationship"
                              )
                            }
                          />
                        }
                        label="In a relationship"
                      />
                    </div>

                    <div>
                      <FormControlLabel
                       sx={clientFormStyles.radioBtnLabel}
                       id="checbox"
                        control={
                          <Checkbox
                            checked={
                              props.values.relationship_status === "married"
                            }
                            onChange={() =>
                              props.setFieldValue(
                                "relationship_status",
                                "married"
                              )
                            }
                          />
                        }
                        label="Married"
                      />
                    </div>

                    <div>
                      <FormControlLabel
                       sx={clientFormStyles.radioBtnLabel}
                       id="checbox"
                        control={
                          <Checkbox
                            checked={
                              props.values.relationship_status === "divorced"
                            }
                            onChange={() =>
                              props.setFieldValue(
                                "relationship_status",
                                "divorced"
                              )
                            }
                          />
                        }
                        label="Divorced"
                      />
                    </div>

                    <div>
                      <FormControlLabel
                       sx={clientFormStyles.radioBtnLabel}
                       id="checbox"
                        control={
                          <Checkbox
                            checked={
                              props.values.relationship_status === "engaged"
                            }
                            onChange={() =>
                              props.setFieldValue(
                                "relationship_status",
                                "engaged"
                              )
                            }
                          />
                        }
                        label="Engaged"
                      />
                    </div>
                  </div>

                  {props.errors.relationship_status && (
                    <FormHelperText error sx={{ marginLeft: "70px" }}>
                      {props.errors.relationship_status}
                    </FormHelperText>
                  )}
                </div>
              </div>
              <div className={styles.stepperBtn}>
                <Button
                  className={styles?.naviBtn}
                  color="primary"
                  disabled={step === 0}
                  onClick={handleBackButtonClick}
                  sx={{ mr: 1 }}
                >
                  Back
                </Button>

                <Button
                  className={styles?.naviBtn}
                  type="submit"
                  // onClick={handleNextButtonClick}
                  // disabled={selectedProviderId === ""}
                >
                  {step === steps.length - 1 ? "Done" : "Next"}
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </Grid>
      <Grid md={3} className={styles.imgCont}>
        <img className={styles.formImg} src={formImg} />
      </Grid>
    </Grid>
  );
};

export default ClientCheckBox;
