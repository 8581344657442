import React from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import styles from "./clientform.module.css";

const Gender = ({ genderRef, disabled }) => {
  const [gender, setSelectedGender] = React.useState(
    genderRef?.current || "female"
  );

  const handleGender = (e) => {
    setSelectedGender(e.target.id);
    genderRef.current = e.target.id;
  };

  return (
    <div>
      <div>
        <p className={styles.feildLabel}>Gender</p>
      </div>
      <Paper className={styles.otherInput}>
        <Stack
          direction="row"
          spacing={3}
          style={{
            gap: "30px",
            padding: "5px 10px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button
            className={
              gender === "female"
                ? styles.selectedGender
                : styles.unselectedGender
            }
            variant={gender === "female" ? "contained" : "text"}
            id="female"
            onClick={handleGender}
            disabled={disabled}
          >
            Female
          </Button>
          <Button
            className={
              gender === "male"
                ? styles.selectedGender
                : styles.unselectedGender
            }
            variant={gender === "male" ? "contained" : "text"}
            id="male"
            onClick={handleGender}
            disabled={disabled}
          >
            Male
          </Button>
          <Button
            className={
              gender === "pns" ? styles.selectedGender : styles.unselectedGender
            }
            variant={gender === "pns" ? "contained" : "text"}
            id="pns"
            onClick={handleGender}
            disabled={disabled}
          >
            Other
          </Button>
        </Stack>
      </Paper>
    </div>
  );
};

export default Gender;
