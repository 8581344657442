import * as React from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import styles from "./clientform.module.css";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Box, FormHelperText } from "@mui/material";
import { Formik } from "formik";
import ClientFormValidationSchema from "../../schema/clientForm";
import { useSelector, useDispatch } from "react-redux";
import { setStepData, clearStep1Data } from "../../redux/slice";
import Password from "./password";
import Gender from "./gender";
import "react-phone-number-input/style.css";
import "./client-form.css";
import PhoneInput from "react-phone-number-input";
import { clientFormStyles } from "./styles";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import CountryDropDown from "./country-dropdown";

const defaultValues = {
  dob: "",
  city: "",
  country: "",
  state: "",
  confirm_password: "",
  email: "",
  emergency_contact: "",
  emergency_contact_name: "",
  emergency_contact_relationship: "",
  first_name: "",
  gender: "female",
  last_name: "",
  password: "",
  phone: "",
  reference_platform: "",
};

const ClientIntakeForm = ({
  handleNextButtonClick,
  // handleBackButtonClick,
  step,
  hideFilledData,
  updateEmail,
}) => {
  const dispatch = useDispatch();
  const step1Data = useSelector((state) => state?.slice?.signup?.step1Data);
  const [userData, setUserData] = React.useState({ ...step1Data });
  const genderRef = React.useRef(step1Data?.gender);
  const steps = [0, 1, 2];

  React.useEffect(() => {
    setUserData({ ...step1Data })
  }, [step1Data])

  return (
    <>
      <div className="client-form">
        <Formik
          className="client-form"
          enableReinitialize
          initialValues={userData}
          key={new Date().getMilliseconds()}
          onSubmit={(values) => {
            const trimmedValues = {
              ...values,
              first_name: values.first_name.trim(),
              last_name: values.last_name.trim(),
              emergency_contact_name: values.emergency_contact_name.trim(),
            };

            dispatch(
              setStepData({
                step: 0,
                data: {
                  ...trimmedValues,
                  gender: genderRef.current,
                },
              })
            );
            handleNextButtonClick(); // Handle navigation to the next step
          }}
          validationSchema={ClientFormValidationSchema}
        >
          {(props) => (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                props.handleSubmit(e);
                props.setFieldTouched("dob", true);
              }}
            >
              <Grid container justifyContent="center" md={12} sm={12}>
                <div className={styles.nameFeildDiv}>
                  <div>
                    <div>
                      <p className={styles.feildLabel}>First name</p>
                    </div>
                    <Paper className={styles.nameInput}>
                      <InputBase
                        id="first_name"
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Enter First Name"
                        value={props.values.first_name}
                        disabled={hideFilledData}
                        onChange={props.handleChange}
                        // onBlur={props.handleBlur}
                      />
                    </Paper>
                    {props.errors.first_name && props?.touched?.first_name && (
                      <FormHelperText
                        className={styles.lastname_errorMsg}
                        error
                        sx={{ marginLeft: "10px" }}
                      >
                        {props.errors.first_name}
                      </FormHelperText>
                    )}
                  </div>
                  <div>
                    <div>
                      <p className={styles.feildLabel}>Last name</p>
                    </div>
                    <Paper className={styles.nameInput}>
                      <InputBase
                        id="last_name"
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Enter Last Name"
                        value={props.values.last_name}
                        disabled={hideFilledData}
                        onChange={props.handleChange}
                        // onBlur={props.handleBlur}
                      />
                    </Paper>
                    {props.errors.last_name && props?.touched?.last_name && (
                      <FormHelperText
                        className={styles.lastname_errorMsg}
                        error
                        sx={{ marginLeft: "10px" }}
                      >
                        {props.errors.last_name}
                      </FormHelperText>
                    )}
                  </div>
                </div>

                <div className={styles.nameFeildDiv}>
                  <div>
                    <div>
                      <p className={styles.feildLabel}>Email Address</p>
                    </div>
                    <Paper className={styles.otherInput}>
                      <InputBase
                        id="email"
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Enter Email Address"
                        value={props.values.email}
                        onChange={(e) => {
                          props.handleChange(e);
                          updateEmail(e.target.value);
                        }}
                        disabled={hideFilledData}
                      />
                    </Paper>
                    {props.errors.email && props.touched.email && (
                      <FormHelperText error sx={{ marginLeft: "10px" }}>
                        {props.errors.email}
                      </FormHelperText>
                    )}
                  </div>
                </div>

                <div className={styles.nameFeildDiv}>
                  <div className="password">
                    <Password
                      value={props.values.password}
                      onChange={props.handleChange}
                      error={props.errors.password}
                      touch={props.touched.password}
                      id="password"
                      placeholder="Enter Password"
                      label="Password"
                    />
                  </div>
                </div>

                <div className={styles.nameFeildDiv}>
                  <div className="password">
                    <Password
                      value={props.values.confirm_password}
                      onChange={props.handleChange}
                      error={props.errors.confirm_password}
                      touch={props.touched.confirm_password}
                      id="confirm_password"
                      placeholder="Confirm Password"
                      label="Confirm Password"
                    />
                  </div>
                </div>

                <div className={styles.nameFeildDiv}>
                  <div>
                    <div>
                      <p className={styles.feildLabel}>Phone</p>
                    </div>
                    <Paper className={styles.phoneInput}>
                      <PhoneInput
                        id="phone"
                        placeholder="Enter phone number"
                        value={props.values.phone}
                        disabled={hideFilledData}
                        onChange={(value) => {
                          props.setFieldValue("phone", value);
                        }}
                        international={true}
                        style={{
                          paddingLeft: "1rem",
                        }}
                        defaultCountry="PK"
                      />
                    </Paper>
                    {props.errors.phone && props.touched.phone && (
                      <FormHelperText error sx={{ marginLeft: "10px" }}>
                        {props.errors.phone}
                      </FormHelperText>
                    )}
                  </div>
                </div>
                {/* gender */}
                <div className={styles.nameFeildDiv}>
                  <Gender genderRef={genderRef} disabled={hideFilledData} />
                </div>

                <div className={styles.nameFeildDiv}>
                  <div>
                    <div>
                      <p className={styles.feildLabel}>Date Of Birth</p>
                    </div>
                    <Paper className={styles.otherInput}>
                      <div className="signup-date-picker">
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <DatePicker
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              height: "50px",
                              "& .MuiOutlinedInput-notchedOutline": {
                                border: "none !important",
                              },
                            }}
                            disabled={hideFilledData}
                            value={
                              props?.values?.dob &&
                              props?.values?.dob === "object"
                                ? props?.values?.dob
                                : props?.values?.dob &&
                                  props?.values?.dob !== "object"
                                ? moment(props?.values?.dob)
                                : undefined
                            }
                            onChange={(value) => {
                              if (value) {
                                if (typeof value !== "object") {
                                  props?.setFieldValue("dob", value);
                                } else {
                                  props?.setFieldValue(
                                    "dob",
                                    moment(value.toDate()).format("YYYY-MM-DD")
                                  );
                                }
                              }
                            }}
                          />
                        </LocalizationProvider>
                      </div>
                    </Paper>
                    {props.errors.dob && props.touched.dob && (
                      <FormHelperText error sx={{ marginLeft: "10px" }}>
                        {props.errors.dob}
                      </FormHelperText>
                    )}
                  </div>
                </div>

                <CountryDropDown
                  formikProps={props}
                  userData={userData}
                  disabled={hideFilledData}
                />

                {/* emergency contact no */}
                <div className={styles.nameFeildDiv}>
                  <div>
                    <div>
                      <p className={styles.feildLabel}>
                        Emergency Contact Name
                      </p>
                    </div>
                    <Paper className={styles.otherInput}>
                      <InputBase
                        id="emergency_contact_name"
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Emergency Contact Name"
                        value={props.values.emergency_contact_name}
                        onChange={props.handleChange}
                      />
                    </Paper>
                    {props.errors.emergency_contact_name &&
                      props.touched.emergency_contact_name && (
                        <FormHelperText error sx={{ marginLeft: "10px" }}>
                          {props.errors.emergency_contact_name}
                        </FormHelperText>
                      )}
                  </div>
                </div>
                <div className={styles.nameFeildDiv}>
                  <div>
                    <div>
                      <p className={styles.feildLabel}>Emergency Contact No</p>
                    </div>
                    <Paper className={styles.phoneInput}>
                      <PhoneInput
                        id="emergency_contact"
                        placeholder="Enter phone number"
                        value={props.values.emergency_contact}
                        onChange={(value) => {
                          props.setFieldValue("emergency_contact", value);
                        }}
                        international={true}
                        style={{
                          paddingLeft: "1rem",
                        }}
                        defaultCountry="PK"
                      />
                    </Paper>
                    {props.errors.emergency_contact &&
                      props.touched.emergency_contact && (
                        <FormHelperText error sx={{ marginLeft: "10px" }}>
                          {props.errors.emergency_contact}
                        </FormHelperText>
                      )}
                  </div>
                </div>

                <div className={styles.nameFeildDiv}>
                  <div>
                    <div>
                      <p className={styles.OptionFeildLabel}>
                        Relationship to emergency contact
                      </p>
                    </div>
                    <div className={styles.checboxDiv}>
                      <div>
                        <FormControlLabel
                          sx={clientFormStyles.radioBtnLabel}
                          id="checbox"
                          control={
                            <Checkbox
                              checked={
                                props.values.emergency_contact_relationship ===
                                "father"
                              }
                              onChange={() =>
                                props.setFieldValue(
                                  "emergency_contact_relationship",
                                  "father"
                                )
                              }
                            />
                          }
                          label="Father"
                        />
                      </div>

                      <div>
                        <FormControlLabel
                          sx={clientFormStyles.radioBtnLabel}
                          id="checbox"
                          control={
                            <Checkbox
                              checked={
                                props.values.emergency_contact_relationship ===
                                "mother"
                              }
                              onChange={() =>
                                props.setFieldValue(
                                  "emergency_contact_relationship",
                                  "mother"
                                )
                              }
                            />
                          }
                          label="Mother"
                        />
                      </div>

                      <div>
                        <FormControlLabel
                          sx={clientFormStyles.radioBtnLabel}
                          id="checbox"
                          control={
                            <Checkbox
                              checked={
                                props.values.emergency_contact_relationship ===
                                "son/daughter"
                              }
                              onChange={() =>
                                props.setFieldValue(
                                  "emergency_contact_relationship",
                                  "son/daughter"
                                )
                              }
                            />
                          }
                          label="Son/Daughter"
                        />
                      </div>

                      <div>
                        <FormControlLabel
                          sx={clientFormStyles.radioBtnLabel}
                          id="checbox"
                          control={
                            <Checkbox
                              checked={
                                props.values.emergency_contact_relationship ===
                                "husband/wife"
                              }
                              onChange={() =>
                                props.setFieldValue(
                                  "emergency_contact_relationship",
                                  "husband/wife"
                                )
                              }
                            />
                          }
                          label="Husband/Wife"
                        />
                      </div>

                      <div>
                        <FormControlLabel
                          sx={clientFormStyles.radioBtnLabel}
                          id="checbox"
                          control={
                            <Checkbox
                              checked={
                                props.values.emergency_contact_relationship ===
                                "brother/sister"
                              }
                              onChange={() =>
                                props.setFieldValue(
                                  "emergency_contact_relationship",
                                  "brother/sister"
                                )
                              }
                            />
                          }
                          label="Brother/Sister"
                        />
                      </div>
                    </div>
                    {props.errors.emergency_contact_relationship &&
                      props?.touched?.emergency_contact_relationship && (
                        <FormHelperText error sx={{ marginLeft: "10px" }}>
                          {props.errors.emergency_contact_relationship}
                        </FormHelperText>
                      )}
                  </div>
                </div>
                {/* hear about us field */}

                <div className={styles.nameFeildDiv}>
                  <div className={styles.nameFeildDiv}>
                    <div>
                      <div>
                        <p className={styles.feildLabel}>
                          {" "}
                          Where did you hear about us?
                        </p>
                      </div>
                      <Box>
                        <Paper className={styles.otherInput}>
                          <Select
                            sx={clientFormStyles.dropDownInput}
                            id="reference_platform"
                            name="reference_platform"
                            fullWidth
                            value={props?.values?.reference_platform}
                            onChange={(event) =>
                              props?.setFieldValue(
                                "reference_platform",
                                event.target.value
                              )
                            }
                            error={
                              props?.touched.reference_platform &&
                              Boolean(props?.errors.reference_platform)
                            }
                          >
                            {constant_Values?.reference_platform.map(
                              (option) => (
                                <MenuItem
                                  className="menu-item-reference-platform"
                                  sx={clientFormStyles.timeZoneInput}
                                  value={option?.value}
                                  key={option.id}
                                >
                                  {option?.label}
                                </MenuItem>
                              )
                            )}
                          </Select>
                        </Paper>
                        <FormHelperText error sx={{ marginLeft: "10px" }}>
                          {props.touched.reference_platform &&
                            props.errors.reference_platform}
                        </FormHelperText>
                      </Box>
                    </div>
                  </div>
                </div>
              </Grid>

              <div className={styles.stepperBtn}>
                {/* <Button
                className={styles?.naviBtn}
                color="primary"
                disabled={step === 0}
                onClick={handleBackButtonClick}
                sx={{ mr: 1 }}
              >
                Back
              </Button> */}

                {step === 0 && (
                  <Button
                    variant="text"
                    style={{ color: "#3E5378", textDecoration: "underline" }}
                    // className={styles?.naviBtn}
                    color="secondary"
                    onClick={() => {
                      setUserData({ ...defaultValues });
                      dispatch(clearStep1Data());
                    }}
                    sx={{ mr: 1 }}
                  >
                    Clear Form
                  </Button>
                )}

                <Button className={styles?.naviBtn} type="submit">
                  {step === steps.length - 1 ? "Done" : "Next"}
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </>
  );
};

export const constant_Values = {
  reference_platform: [
    {
      label: "Google",
      value: "google",
    },
    {
      label: "Facebook",
      value: "facebook",
    },
    {
      label: "Instagram",
      value: "instagram",
    },
    {
      label: "LinkedIn",
      value: "linkedin",
    },
    {
      label: "Friends & Family",
      value: "friends and family",
    },
    {
      label: "Through a website",
      value: "through a website",
    },
    {
      label: "Awareness Session",
      value: "awareness session",
    },
    {
      label: "Other",
      value: "other",
    },
  ],
};

export default ClientIntakeForm;
