import React, { useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { setStepData, setUser } from "../../redux/slice";
import { Tabs, Tab } from "@material-ui/core";
import styles from "./signup.module.css";
import { useNavigate } from "react-router-dom";
import {
  SuccessModal,
  ModalError,
} from "../../components/modals/Modal.Success";
import Loader from "../../components/loader";
import ClientIntakeForm from "../../components/client-form";
import ClientCheckBox from "../../components/client-checkbox-feild";
import { prevStep, nextStep } from "../../redux/slice";
import ClientChildren from "../../components/client-children-field";
import { clearAllData } from "../../redux/slice";
import { networkRequest } from "../../utils/api";
import { Fade } from "@mui/material";
import moment from "moment";

const SignupPage = () => {
  const uuid = new URLSearchParams(window.location.search).get("ut") || "";
  const step = useSelector((state) => state?.slice?.signup?.step);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState("");
  const [modalStatus, setModalStatus] = useState(true);
  const emailRef = useRef("");
  const [openFailureModal, setOpenFailureModal] = useState(false);

  const step1Data = useSelector((state) => state?.slice?.signup?.step1Data);
  const step2Data = useSelector((state) => state?.slice?.signup?.step2Data);

  const updateEmailRef = (value) => {
    emailRef.current = value;
  };

  const handleFailureModalClose = () => {
    setOpenFailureModal(false);
  };
  const { mutate, isLoading, error } = useMutation(networkRequest, {
    onSuccess: (res) => {
      const { data, message } = res;
      if (data) {
        updateEmailRef(data);
        dispatch(setUser(data));
      }
      setSuccessMessage(message);
    },
    onError: (error) => {
      const isVerified = error?.includes("User Account Not verified");

      error?.includes(
        "OTP already Generated. Either enter the recent OTP sent to email or wait for it to expire. Estimated expiry 15 min"
      ) || isVerified
        ? setSuccessMessage(
            isVerified
              ? "Please check your email and verify the OTP to complete your registration!"
              : error
          )
        : setOpenFailureModal(true);
    },
  });
  const { isLoading: isFetchingUserDetails, error: fetchingUserDetailError } =
    useQuery({
      queryKey: ["GET_SIGNUP_USER_DETAIL", uuid],
      queryFn: () =>
        networkRequest({
          method: "get",
          url: `get-user?ut=${uuid}`,
        }),
      cacheTime: 0,
      enabled: !!uuid,
      onSuccess: (response) => {
        updateEmailRef(response?.email || step1Data?.email);
        dispatch(
          setStepData({
            step: 0,
            data: {
              ...step1Data,
              first_name: response?.first_name || step1Data?.first_name,
              last_name: response?.last_name || step1Data?.last_name,
              gender: response?.gender || step1Data?.gender,
              city: response?.city || step1Data?.city,
              country: response?.country || step1Data?.country,
              state: response?.state || step1Data?.state,
              phone: response?.phone || step1Data?.phone,
              age: response?.age || step1Data?.age,
              email: response?.email || step1Data?.email,
              dob: response?.dob
                ? moment(response?.dob).format("YYYY-MM-DD")
                : step1Data?.dob
                ? moment(step1Data?.dob).format("YYYY-MM-DD")
                : undefined,
            },
          })
        );
      },
    });

  const [_, setValue] = useState(0);

  useEffect(() => {
    !uuid && dispatch(clearAllData());
  }, [])

  const handleTabs = (e, val) => {
    setValue(val);
  };

  function gotoSignin() {
    navigate("/auth/login");
  }

  const handleNextButtonClick = () => {
    dispatch(nextStep());
  };

  const handleBackButtonClick = () => {
    dispatch(prevStep());
  };

  const handleSignUp = (values) => {
    const data = {
      ...step1Data,
      ...step2Data,
      ...values,
    };

    // console.log("DATA", data);
    mutate({
      method: uuid ? "put" : "post",
      url: "/client/register",
      data,
    });
  };

  return (
    <Fade in={true} timeout={300}>
      <div className={styles.formDiv}>
        <Tabs value={true} onChange={handleTabs} centered>
          <Tab
            className={styles.tab}
            label="SIGN IN"
            onClick={gotoSignin}
            value={false}
          />
          <Tab className={styles.tab} label="SIGN UP" value={true} />
        </Tabs>

        <div align="center">
          <div className={styles.headlineMargin}>
            <div className={styles.secondHeading}>
              Hello. Thank you for choosing Be Me. Please take a few minutes to
              fill out our form to help serve you better. 
            </div>
          </div>
        </div>
        <div>
          {successMessage ? (
            <SuccessModal
              open={modalStatus}
              message={successMessage}
              handleClose={() => {
                setModalStatus(false);
                navigate(
                  `/auth/otp-signup/${emailRef.current || step1Data?.email}`
                );
                dispatch(clearAllData());
              }}
            />
          ) : (
            ""
          )}

          {error || fetchingUserDetailError ? (
            <ModalError
              open={openFailureModal}
              message={error || fetchingUserDetailError}
              handleClose={handleFailureModalClose}
            />
          ) : (
            ""
          )}
          {isLoading || isFetchingUserDetails ? (
            <Loader />
          ) : (
            <div>
              {step === 0 && (
                <ClientIntakeForm
                  handleNextButtonClick={handleNextButtonClick}
                  handleBackButtonClick={handleBackButtonClick}
                  updateEmail={updateEmailRef}
                  step={step}
                  hideFilledData={!!uuid}
                />
              )}
              {step === 1 && (
                <ClientCheckBox
                  handleNextButtonClick={handleNextButtonClick}
                  handleBackButtonClick={handleBackButtonClick}
                  step={step}
                />
              )}
              {step === 2 && (
                <ClientChildren
                  handleNextButtonClick={handleNextButtonClick}
                  handleBackButtonClick={handleBackButtonClick}
                  step={step}
                  handleSignUp={handleSignUp}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </Fade>
  );
};

export default SignupPage;
